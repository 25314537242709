import { writable, derived } from 'svelte/store';
import { dmBasicWritable } from "./store-basic.js";
import { loadInitialConfigForOrganization } from "./stores.js";
import { dm1 } from "./stores.js";

let userValue;
export const userStore = writable(userValue);
const USER_LOCAL_STORAGE_KEY = "user";


let authTokenValue;
export const authTokenStore = writable(authTokenValue);
const AUTH_TOKEN_LOCAL_STORAGE_KEY = "authToken";


export const userLoggedInStore = derived(
  [userStore, authTokenStore], ([$userStore, $authTokenStore]) => {
    return $authTokenStore != undefined && $userStore != undefined;
  }
);

export const userIsAdminStore = derived(
  userStore, $userStore => {
    return $userStore != undefined && $userStore.authorities && $userStore.authorities.includes("ROLE_ADMIN");
  }
);


export const userIsBulkEdtiorStore = derived(
  userStore, $userStore => {
    return $userStore != undefined && ($userStore.authorities && $userStore.authorities.includes("ROLE_DM_BULK")
      || $userStore.authorities.includes("ROLE_ADMIN"));
  }
);

export const userIsSegmentedScreensEdtiorStore = derived(
  userStore, $userStore => {
    return $userStore != undefined && ($userStore.authorities && $userStore.authorities.includes("ROLE_DM_SEGMENTED")
      || $userStore.authorities.includes("ROLE_ADMIN"));
  }
);

let dm;
dm1.subscribe(updatedDm => {
  dm = updatedDm;
});

export function login(user, authToken) {
  console.log("login()");
  storeUser(user);
  storeAuthToken(authToken);
}

function storeUser(user) {
  userValue = user;
  userStore.set(user);

  console.log("Reseting station");
  // reset station after login
  dm.group1stationId = '';
  dm.group1stationName = '';
  dm1.set(dm);

  updateApiConfigFromUser(user);
  const userJson = JSON.stringify(user);
  console.log("Storing user into localStorage: " + userJson);
  localStorage.setItem(USER_LOCAL_STORAGE_KEY, userJson);
}

function storeAuthToken(authToken) {
  authTokenValue = authToken;
  authTokenStore.set(authToken);
  console.log("Storing auth token into localStorage: " + authToken);
  localStorage.setItem(AUTH_TOKEN_LOCAL_STORAGE_KEY, authToken);
}

export function getUser() {
  console.log("Loading user from localStorage");
  if (!userValue) {
    const userFromLocalStorageJson = localStorage.getItem(USER_LOCAL_STORAGE_KEY);

    console.log("Loaded user from localStorage: " + userFromLocalStorageJson);
    if (userFromLocalStorageJson) {
      const userFromLocalStorage = JSON.parse(userFromLocalStorageJson);
      console.log("Stroing user into store : " + JSON.stringify(userFromLocalStorage));
      userValue = userFromLocalStorage;
      userStore.set(userFromLocalStorage);
      updateApiConfigFromUser(userFromLocalStorage);
    }
  }
  return userValue;
}

export function getAuthToken() {
  console.log("Loading auth token from localStorage");
  if (!authTokenValue) {
    const authTokenFromLocalStorage = localStorage.getItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
    console.log("Loaded auth token localStorage: " + JSON.stringify(authTokenFromLocalStorage));
    if (authTokenFromLocalStorage) {
      authTokenValue = authTokenFromLocalStorage;
      authTokenStore.set(authTokenFromLocalStorage);
    }
  }
  return authTokenValue;
}

export function loadUserFromLocalStorage() {
  console.log("loadUserFromLocalStorage()");
  getUser();
  getAuthToken();
}

export function logout() {
  console.log("logout()");
  userStore.set(undefined);
  localStorage.removeItem(USER_LOCAL_STORAGE_KEY);

  authTokenStore.set(undefined);
  localStorage.removeItem(AUTH_TOKEN_LOCAL_STORAGE_KEY);
}


let dmBasic;
dmBasicWritable.subscribe(updatedDmBasic => {
  dmBasic = updatedDmBasic;
});


function updateApiConfigFromUser(user) {
  console.log("updateApiConfigFromUser", user);
  if (user.transportNetwork) {
    const transportNetwork = user.transportNetwork;

    if (transportNetwork.apiNetwork) {
      dmBasic.network = transportNetwork.apiNetwork;
    }
    if (transportNetwork.apiRootUrl) {
      dmBasic.apiRootUrl = transportNetwork.apiRootUrl;
    }
    if (transportNetwork.apiUseJsonp) {
      dmBasic.apiUseJsonp = transportNetwork.apiUseJsonp;
    }
    if (transportNetwork.posRootUrl) {
      dmBasic.posRootUrl = transportNetwork.posRootUrl;
    }

    dmBasicWritable.set(dmBasic);

    loadInitialConfigForOrganization(user.organizationName);
  }
}
