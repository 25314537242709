import { loadUserFromLocalStorage } from "./store-user.js";

String.prototype.supplant = function (o) {
  return this.replace(/{([^{}]*)}/g, (a, b) => {
    const r = o[b];
    return typeof r === "string" || typeof r === "number" ? r : a;
  });
};

String.prototype.removeSpaces = function () {
  const str = this;
  return str.replace(new RegExp(" ", "g"), "-");
};

String.prototype.uriEncode = function () {
  const str = this;
  return encodeURIComponent(str);
};


loadUserFromLocalStorage();