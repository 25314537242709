import { writable } from "svelte/store";

export const dmInitial = {
  // layout
  mainLayout: "",
  contentLayout: "basic",
  splitTwoColumns: false,

  // station groups
  group1numDepartures: 10,
  group1stationId: "",
  group1stationName: "",
  group1title: "",

  // debug options
  debug: false,
  // debugUi: false,
  debugConfig: false,

  // language
  lang: "de",

  // configurator
  showBlinker: false,
  showConfigurator: true,
  supportMail: "",

  // section sizes
  contentHeight: 100,
  headerHeight: 10,
  footerHeight: 10,

  fontScale: 0.5,

  // header showing custom text
  showTopHeader: false,
  headerText: "",
  headerFontScale: 1.5,

  // header showing station name
  showHeader: true,
  titleRows: 1,
  titleFontScale: 1.1,
  stationHeaderPos: "left",

  // legend shown above the departures
  legendRows: 0.5,
  legendFontScale: 1.4,

  // service alerts / disruption messages
  animatedDisruptionMessages: false,
  marqueeRows: 1,
  marqueeFontScale: 1.2,
  marqueeSpeed: "8000",
  //serviceAlertHeaderSeparator: ": ",
  serviceAlertDuration: 30,

  // footer showing logo and clock
  showFooter: true,
  footerText: "",
  footerFontScale: 0.7,
  showDisclaimer: false,
  // disclaimerText: "",
  footerTimeFormat: "dddd, DD. MMMM YYYY, HH:mm",
  showClock: true,
  showAnalogClock: true,
  clockScale: 0.8,
  clockPaddingHor: 1.2,
  clockPaddingVer: 1,
  showLogo: true,
  logoUrl: "",

  // departures business logic
  minNumberOfDepartures: 1,
  defaultNumberOfDepartures: 10,
  maxNumberOfDepartures: 30,
  //hourOffset: 2,
  refreshInterval: 20,
  //cacheHourOffset: 12,
  //cacheRefreshInterval: 300,
  //maxNumberOfAttempts: 20,
  configRefreshInterval: 28,

  // versionCheckInterval: 300,

  // departures table
  departureFontScale: 1.08,

  // columns
  columnConfigId: "",
  columnConfig: "",

  showLineType: true,
  showPlatforms: true,
  showLineColors: true,
  showRealTimeIcon: false,
  showAtStationIcon: true,
  departureMarkLate: true,

  // destination column
  showPlacesAlways: false,
  placesSeparator: ", ",
  useTripHeadsign: true,

  // via column
  useShortVia: false,
  showVia: false,

  // departure time column
  departureTimeRelative: false,
  departureTimeRelativeWhenRt: true,
  departureTimePrefix: "",
  departureTimePostfix: "'",
  departureTimeAlwaysPlan: false,

  // departure table track layout
  row1Height: 36,
  row2MarginTop: 7,
  bodyMarginVer: 7,
  bodyMarginHor: 2,

  colLineWidth: 16,
  colTimeWidth: 18,
  colDepartureFontScale: 1,
  colMessageFontScale: 0.65,

  screenWidth: 0,
  screenHeight: 0,

  // filtering
  agenciesMode: "include",
  agencies: "",
  linesMode: "include",
  lines: "",
  platformsMode: "include",
  platforms: "",
  directions: "",
  destinationsMode: "include",
  destinations: "",
  platformIds: [],

  // colors and font
  bgColor: undefined,
  defaultBgColor: "#000066",
  fgColor: undefined,
  defaultFgColor: "#FFFFFF",
  headerBgColor: undefined,
  headerFgColor: undefined,
  footerBgColor: undefined,
  footerFgColor: undefined,
  splitLineColor: undefined,
  defaultSplitLineColor: "#FFFFFF",
  lateColor: undefined,
  defaultLateColor: "#FFFF00",
  iconStyle: "",
  font: "",

  // service alerts colors
  serviceAlertBgColor: undefined,
  defaultServiceAlertBgColor: "#FFFFFF",
  serviceAlertFgColor: undefined,
  defaultServiceAlertFgColor: "#000000",
  marqueeBgColor: undefined,
  defaultMarqueeBgColor: "#FF0000",
  marqueeFgColor: undefined,
  defaultMarqueeFgColor: "#FFFFFF",

  // Configurator UI state, not exported to Departure Monitor
  uiSelectedTabIndex: 0,
  uiSelectedLayout: "oneColumn",
  uiRatio: "169",
  uiFromTemplateId: undefined,
  uiShowCustomStationName: false
};


const dmInitialPerOrganization = {

  LBA: {
    defaultBgColor: "#eff2b2",
    defaultFgColor: "#000000",
    headerBgColor: "#d2e44a",
    headerFgColor: "#ffffff",
    footerBgColor: '#d2e44a',
    footerFgColor: '#006bb6',
    defaultLateColor: '#ff0000',
    iconStyle: 'black',
    defaultSplitLineColor: "#a0ad36",
    // showLineType: false,
    // showPlatforms: false,
    showAnalogClock: false,
    titleRows: 1.2,
    titleFontScale: 1.4,
    placesSeparator: ' ',
    departureTimePrefix: 'in ',
    departureTimeAbsoluteFormat: 'H:mm',
    departureTimeRelative: true,
    departureTimeRelativeWhenRt: false,
    showPlacesAlways: true,
  },

  OSTWIND: {
    footerBgColor: '#0059A1',
    showPlatforms: false,
    showDisclaimer: true,
    showAnalogClock: false,
    disclaimerText: 'Die auf dieser Webseite publizierten Daten dürfen in keiner Weise manipuliert oder zu wirtschaftlichen Zwecken verwendet werden.',
    departureTimeRelativeWhenRtAbsoluteAfter: 30,
    animatedDisruptionMessages: true,
  },

  TPF: {
    showLogo: false,
    departureMarkLate: false,
    showAtStationIcon: false,
  }
};


export function loadInitialConfigForOrganization(organization) {
  if (!organization) {
    return;
  }
  const organizationUppercase = organization.toUpperCase();
  console.log('Loading initial config for organization: ' + organizationUppercase);

  if (Object.prototype.hasOwnProperty.call(dmInitialPerOrganization, organizationUppercase)) {
    console.log('Custom initial config found for organization: ' + organizationUppercase);
    const initialForCurrentOrganization = dmInitialPerOrganization[organizationUppercase];
    for (const property in initialForCurrentOrganization) {
      if (Object.prototype.hasOwnProperty.call(initialForCurrentOrganization, property)) {
        const customValue = initialForCurrentOrganization[property];
        console.log(`Replaced ${property}=${customValue}`);
        dmInitial[property] = customValue;
      }
    }

    loadDmFromInitial();
  }
}



const dm = {};

loadDmFromInitial();

function loadDmFromInitial() {
  for (const key in dmInitial) {
    dm[key] = dmInitial[key];
  }
}

export const dm1 = writable(dm);

