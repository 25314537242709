import { readable } from 'svelte/store';
import { writable } from 'svelte/store';

export const dmBasicInitial = {
  supportedLanguages: [
    { name: 'en', label: 'English' },
    { name: 'de', label: 'Deutsch' },
    { name: 'fr', label: 'French' },
    { name: 'it', label: 'Italian' }
  ],

  minNumberOfDepartures: 1,
  maxNumberOfDepartures: 30,

  managmentApiRootUrl: 'api/',
  apiRootUrl: '/rest/v0/',
  apiUseJsonp: false,
  JSONPCallback: 'callback=JSON_CALLBACK',
  network: 'test',
  posRootUrl: 'http://pos-preview.wemlin.com/{network}/',
  departuresByStationUrl: '{apiRootUrl}networks/{network}/stations/{station}/{startTime}/{endTime}',
  stationsUrl: '{apiRootUrl}networks/{network}/stations',
  stationsForCityUrl: '{apiRootUrl}networks/{network}/station_groups/{city}',
  currentTimeUrl: '{apiRootUrl}current_time',
  directionsUrl: '{apiRootUrl}networks/{network}/directions?stationId={stationId}',
  platformsUrl: '{apiRootUrl}networks/{network}/platforms?stationId={stationId}',

  host_config: {
    'pos.wemlin.com': {
      managmentApiRootUrl: '/api/',
      network: 'switzerland',
      apiRootUrl: '/dataset/rest/v0/',
      apiUseJsonp: false,
      posRootUrl: '{protocol}//pos.wemlin.com/monitor/',
    },
    'pos-preview.wemlin.com': {
      managmentApiRootUrl: '/api/',
      network: 'switzerland',
      apiRootUrl: '/dataset/rest/v0/',
      apiUseJsonp: false,
      posRootUrl: 'http://pos-preview.wemlin.com/monitor/',
    },
    localhost: {
      managmentApiRootUrl: 'http://localhost:8080/api/',
      network: 'blt',
      apiRootUrl: 'http://data-preview.wemlin.com/rest/v0/',
      apiUseJsonp: true,
      posRootUrl: 'http://pos-preview.wemlin.com/blt/',
    }
  }

};


const hostConfig = dmBasicInitial.host_config;
const currentHostname = window.location.hostname;
console.log('Hostname: ' + currentHostname);

if (Object.prototype.hasOwnProperty.call(hostConfig, currentHostname)) {
  console.log('Custom config found for hostname: ' + currentHostname);
  const hostConfigForCurrentHost = hostConfig[currentHostname];
  for (const property in hostConfigForCurrentHost) {
    if (Object.prototype.hasOwnProperty.call(hostConfigForCurrentHost, property)) {
      const customValue = hostConfigForCurrentHost[property];
      console.log(`Replaced ${property}=${customValue}`);
      dmBasicInitial[property] = customValue;
    }
  }
}


export const dmBasic = readable(dmBasicInitial);
export const dmBasicWritable = writable(dmBasicInitial);


